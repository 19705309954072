import React from 'react';
import { Icon } from '~/components';
// import logo from '../assets/png/logo.png';

export interface LogoProps {
	isBig?: boolean,
	styles?: React.CSSProperties,
}

// const size = {
// 	big: {
// 		width: 326,
// 		height: 52,
// 	},
// 	small: {
// 		width: 121,
// 		height: 20,
// 	},
// } as const;

export const Logo: React.FC<LogoProps> = (props) => {

	const { isBig = false, styles } = props;

	return (
		<div style={styles}>
			<Icon name={isBig ? 'logoBig' : 'logo'} />
		</div>
	);

}
