import { RequestViewComponent as RC } from '~/services';
import { Legend, VideoWrap } from '~/components';

import { normalizeURL } from '~/utils';
import { RequestEarnings, RequestInfo, RequestPerson } from '../Request';

export const Endorsement: RC<'endorsement'> = (props) => {

	const { request: { profile, ...request }, account } = props;

	const {
		status,
		endorsement,
		request_amount,
	} = request;

	return (
		<>
			{!!(account.user_type !== 2 && status >= 4) && (
			<VideoWrap
				watermark
				source={{ uri: endorsement.video }} />
			)}
			<RequestInfo
				request={request} />
			<RequestPerson
				profile={profile}
				request={request} />
			<Legend
				label="Endorsement information"
				children={endorsement.task} />
			{!!endorsement.link && (
			<Legend
				label="Endorsement link"
				children={(
					!!normalizeURL(endorsement.link) ? (
					<a
						rel="noreferrer"
						href={normalizeURL(endorsement.link)}
						target="_blank"
						children={endorsement.link}
						className="link" />
					) : (
					<p>{endorsement.link}</p>
					)
				)} />
			)}
			<RequestEarnings
				request={request}
				profile={profile} />
		</>
	);

}
