import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { pick, reject } from 'lodash';

import { lang, linkRegexr } from '~/constants';
import { useAuth, useData } from '~/store';
import { DeepWritableObject, OptionDefault } from '~/types';

import { StepProps, MediaStateArray, MediaState } from '~/services/signup';

import { Button, Input, PhotoButton, RSelect, VideoButton } from '~/components';

import {
	Account,
	handleError,
	IBusinessInfo,
	isAthlete,
	isBusiness,
	isFan,
	socialLinks,
	SocialLinksForm,
	SignUpBusinessInput,
	SignUpFanInput,
	UserRoles,
	MediaPhotoInput,
	MediaVideoInput
} from '~/services';

type Form = {
	first_name: string,
	last_name: string,
	business: DeepWritableObject<IBusinessInfo>,
	gender: OptionDefault,
	school: OptionDefault,
	sport: OptionDefault[],
	links?: SocialLinksForm,
}

type FormMedia = {
	profile_photo: string,
	intro_video?: string,
}

type MediaType = 'photo' | 'video'

class LogicError extends Error {
	public code: string;
	constructor (message: string, code: string) {
		super(message);
		this.name = 'LogicError';
		this.code = code;
		this.stack = (new Error()).stack;
	}
}

async function uploadMedia (
	type: MediaType,
	file: MediaStateArray | MediaState,
	defaultPath?: string,
): Promise<string> {

	if (!file.file) {
		if (defaultPath) {
			return defaultPath;
		}
		throw new LogicError(lang[`${type === 'photo' ? 'PHOTO' : 'VIDEO'}_EMPTINESS`], type);
	}

	if (typeof file.file === 'string') {
		return file.file;
	}

	try {

		const { path } = await Account.media[type](
			{
				[type]: Array.isArray(file.file) ? file.file?.[0] : file.file,
				...(Array.isArray(file.file) && file.file?.[1] ? { second_photo: file.file[1] } : undefined),
			} as MediaPhotoInput & MediaVideoInput
		).promise;

		return path;

	} catch (e) {

		throw new LogicError(`Unable to load the ${type}`, type);

	}

}

export const SignUpPhoto: React.FC<StepProps> = (props) => {

	const {
		step: [ , setStep ],
		data: [ data, setData ],
		form: [ , setForm ],
		steps,
		account,
	} = props;

	const { business } = data;

	const { options, util, intro_video } = useData();

	const { authorize, updateMidAuth } = useAuth();

	const [ photo, setPhoto ] = useState<MediaStateArray>({
		file: account.profile_photo || data.profile_photo,
	});

	const [ video, setVideo ] = useState<MediaState>({
		file: account.intro_video || data.intro_video,
	});

	const { control, handleSubmit } = useForm<Form>({
		defaultValues: {
			first_name: account.first_name || data.first_name,
			last_name: account.last_name || data.last_name,
			gender: util.itemToOption(data.gender[0]) || util.optionById('genders', account.gender),
			business: isBusiness(account) ? {
				name: account.business_info.name || business.name,
				about: account.business_info.about || business.about,
				category_list: account.business_info.category_list || business.category_list,
				description: account.business_info.description || business.description,
				phone: account.business_info.phone || business.phone || account.phone,
				email: account.business_info.email || business.email,
				website: account.business_info.website || business.website,
				single_line_address: account.business_info.single_line_address || business.single_line_address,
			} : undefined,
			...(isAthlete(account) ? {
				school:
					util.itemToOption(data.school[0]) ||
					util.optionById('available_schools', account.athlete_info.school_id) ||
					options.available_schools.length === 1 ?
						options.available_schools[0] :
						undefined,
				sport: util.itemsToOptions(data.sport).length ?
					util.itemsToOptions(data.sport) :
					util.optionsByIds('sports', account.athlete_info.sports.split(',')),
				links: {
					tiktok_link: data.links.tiktok_link || account.tiktok_link,
					twitter_link: data.links.twitter_link || account.twitter_link,
					snapchat_link: data.links.snapchat_link || account.snapchat_link,
					facebook_link: data.links.facebook_link || account.facebook_link,
					instagram_link: data.links.instagram_link || account.instagram_link,
				},
			} : {}),
		},
	});

	const signUp = useCallback(
		async (form: SignUpFanInput | SignUpBusinessInput) => {

			const { account } = 'business_info' in form ?
				await Account.signUpBusiness(form).promise :
				await Account.signUpFan(form).promise;

			authorize({ account });

		},
		[ authorize ]
	);

	const proceed = async (form: Form & FormMedia) => {

		if (isAthlete(account)) {

			const { business: _, gender, school, sport, ...rest } = form;

			setData((data) => ({
				...data,
				...form,
				gender: util.optionsToItems('genders', [ gender ]),
				school: util.optionsToItems('available_schools', [ school ]),
				sport: util.optionsToItems('sports', sport),
			}));

			await updateMidAuth({
				...rest,
				...form.links,
				gender: gender.value,
				sports: sport.map(({ value }) => value).join(','),
				school_id: school.value,
				school_name: school.label,
				school_email: '',
				sports_played: sport[0].value,
			});

			return true;

		}

		if (isBusiness(account)) {
			return signUp({
				business_info: {
					...form.business,
					user_id: account.business_info.user_id,
					picture: form.profile_photo,
				},
			});
		}

		return signUp({
			...form,
			gender: form.gender.value,
		});

	}

	const onSubmit = useCallback(
		async () => new Promise<boolean | void>((resolve) => handleSubmit(
			async (form: Form) => {

				try {

					const _photo = await uploadMedia('photo', photo);

					const _video = await uploadMedia('video', video, intro_video);

					const result = await proceed({
						...form,
						profile_photo: _photo,
						intro_video: _video,
					});

					resolve(result);

				} catch (e) {

					resolve(false);

					if (e instanceof LogicError) {
						const { code, message: error } = e;
						if (code === 'photo') {
							setPhoto((val) => ({ ...val, error }));
						} else if (code === 'video') {
							setVideo((val) => ({ ...val, error }));
						}
						return;
					}

					handleError(e);

				}

			},
			() => resolve(false),
		)()),
		[ handleSubmit, photo, video, intro_video ]
	);

	useEffect(
		() => setForm((val) => ({ ...val, onSubmit, nextDisabled: false })),
		[ setForm, onSubmit ]
	);

	return (
		<div>

			<PhotoButton
				photo={photo.file}
				error={photo.error}
				onPhotoChange={(file) => setPhoto({ file })}
				isCrop
				isAthlete={isAthlete(account)} />

			{!!isFan(account) &&
			<>
				<Input
					name="first_name"
					icon="user"
					rules={{ required: true }}
					control={control}
					placeholder="First name" />
				<Input
					name="last_name"
					icon={null}
					rules={{ required: true }}
					control={control}
					placeholder="Last name" />
				<RSelect
					name="gender"
					icon="user"
					rules={{ required: true }}
					control={control}
					options={options.genders}
					placeholder="Gender" />
			</>
			}

			{!!isBusiness(account) &&
			<>
				<Input
					name="business.name"
					icon="user"
					rules={{ required: true }}
					control={control}
					placeholder="Business name" />
				<Input
					name="business.about"
					icon="description"
					control={control}
					textarea
					placeholder="About" />
				<Input
					name="business.category_list"
					icon="description"
					control={control}
					placeholder="Category" />
				<Input
					name="business.description"
					icon="description"
					control={control}
					textarea
					placeholder="Description" />
				<Input
					name="business.phone"
					icon="phone"
					rules={{ required: true }}
					control={control}
					placeholder="Phone" />
				<Input
					name="business.email"
					icon="mail"
					rules={{ required: true }}
					control={control}
					placeholder="Email" />
				<Input
					name="business.website"
					icon="link"
					rules={{ required: true }}
					control={control}
					placeholder="Website" />
				<Input
					name="business.single_line_address"
					icon="mapPin"
					control={control}
					placeholder="Address" />
			</>
			}

			{!!isAthlete(account) &&
			<>
				<Input
					name="first_name"
					icon="user"
					rules={{ required: true }}
					control={control}
					placeholder="First name" />
				<Input
					name="last_name"
					icon={null}
					rules={{ required: true }}
					control={control}
					placeholder="Last name" />
				<RSelect
					name="sport"
					icon="Sport"
					rules={{ required: true }}
					isMulti
					control={control}
					options={options.sports}
					placeholder="Sport" />
				{(options.available_schools.length > 1) &&
				<RSelect
					name="school"
					icon="School"
					rules={{ required: true }}
					control={control}
					options={options.available_schools}
					placeholder="School" />
				}
				<RSelect
					name="gender"
					icon="user"
					rules={{ required: true }}
					control={control}
					options={options.genders}
					placeholder="Gender" />
				{Object.values(socialLinks).map(([ , icon, name ], i) => (
				<Input
					key={i}
					name={`links.${name}`}
					icon={icon}
					rules={{
						validate: (link) => link && !linkRegexr.test(link) ?
							'Please enter valid link' :
							true
					}}
					control={control}
					placeholder="Link" />
				))}
				<VideoButton
					note="This video is used to introduce yourself to fans and brands and anyone else looking to connect."
					video={video.file}
					error={video.error}
					onError={(error) => setVideo({ error })}
					useDefault
					hideDefault
					onVideoChange={(file) => setVideo({ file })} />
			</>
			}

		</div>
	);

}
